<template>
  <v-container class="content ma-0 pa-2">
    <v-row justify="center" align="center">
      <v-col cols="6">
        <v-autocomplete
          v-model="projectSelected"
          :items="reportTypes"
          item-text="name"
          item-value="id"
          label="Selecione o tipo de relatório"
          outlined
          dense
          hide-details
          :loading="ready"
          :disabled="ready"
          clearable
          @change="selectStructure"
          @click:clear="clearStructure"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <NewStructure :disabled="!!projectSelected" />
      </v-col>

      <v-col cols="3" justify="end" align="end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-badge
              bordered
              color="success"
              icon="mdi-information-outline"
              overlap
              :value="isEnableSaveButton"
            >
              <v-btn
                class="success darken-1 white--text"
                :disabled="!isEnableSaveButton"
                v-on="on"
                @click="update"
              >
                <v-icon left>mdi-content-save</v-icon>
                Salvar alterações
              </v-btn>
            </v-badge>
          </template>
          <span v-if="isEnableSaveButton">
            Alguma alteração foram feitas, ao finalizar clique em salvar!
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row
      v-if="loading"
      justify="center"
      align="center"
      class="pa-4"
      style="height: 50vh"
    >
      <div style="text-align: center">
        <v-progress-circular :size="70" color="primary" indeterminate />
        <h3 class="mt-4">Preparando visualização...</h3>
      </div>
    </v-row>

    <v-tabs v-else v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab> Relatório </v-tab>

      <v-tab> Histórico </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-divider vertical></v-divider>
          <v-col cols="12">
            <div v-if="project?.length">
              <p class="text--secondary">Relatório</p>
              <v-treeview
                :key="key"
                hoverable
                activatable
                return-object
                :items="project"
                item-key="treeViewId"
                item-text="treeViewText"
                item-children="treeViewChildren"
                class="custom-treeview"
                :open.sync="opened"
                @update:active="getSeletectItem"
                @update:open="getOpenItem"
              >
                <!-- <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.componentType">
                    {{ findIcon(item.componentType) }}
                  </v-icon>
                </template> -->
                <template v-slot:append="{ item }">
                  <div class="d-flex">
                    <div v-if="item.type === 'form'" left>
                      <NewStep :report="item" :update="true" />
                    </div>
                    <div
                      v-if="
                        item.type === 'form' ||
                        item.fields ||
                        item.componentType
                      "
                      left
                    >
                      <NewComponent
                        :structure-item="item"
                        :project-structure="selectedStructure"
                      />
                    </div>
                  </div>

                  <div v-if="item.active === true" left>
                    <NewStep :report="item" />
                  </div>
                </template>
              </v-treeview>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <HistoryList />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Structure from '../../../services/StructureReport/Structure'
import { LOAD_REPORT_TYPE } from '../../../vuex/modules/reportType'
import {
  BOOTSTRAP,
  DELETE_ITEM,
  LOAD_STRUCTURE,
  REMOVE_AUTOCOMPLETE_STRUCTURE,
  SET_HISTORY_OPENED_ITEMS,
  SET_STRUCTURE_SELECTED,
  UPDATE_STRUCTURE,
} from '../../../vuex/modules/structure'
import HistoryList from './historyList.vue'
import NewComponent from './newComponent.vue'
import NewStep from './newStep.vue'
import NewStructure from './newStructure.vue'

export default {
  components: {
    NewStructure,
    NewStep,
    NewComponent,

    HistoryList,
  },
  data() {
    return {
      Structure,
      typeLoading: false,

      active: [],
      open: [],
      selectedItem: null,
      selectedStructure: null,
      projectSelected: 0,
      projectsstruture: [],

      key: 0,
      tab: 0,

      stepDialog: false,
      opened: [1],

      reportTypeList: [],
    }
  },
  computed: {
    ...mapState('structure', ['data', 'loading', 'project', 'isModified']),
    ...mapState('reportType', ['reportTypes', 'ready']),

    isEnableSaveButton() {
      return this.projectSelected && this.isModified ? true : false
    },
  },

  watch: {
    project: {
      handler() {
        this.key++
      },
      immediate: true,
    },
  },

  created() {
    this.LOAD_REPORT_TYPE()
  },
  methods: {
    ...mapActions('structure', [
      BOOTSTRAP,
      LOAD_STRUCTURE,
      REMOVE_AUTOCOMPLETE_STRUCTURE,
      UPDATE_STRUCTURE,
      DELETE_ITEM,
    ]),
    ...mapActions('reportType', [LOAD_REPORT_TYPE]),
    ...mapMutations('structure', [
      SET_STRUCTURE_SELECTED,
      SET_HISTORY_OPENED_ITEMS,
    ]),

    getSeletectItem(value) {
      this.selectedItem = value[0]
    },
    getOpenItem(value) {
      this.SET_HISTORY_OPENED_ITEMS(value)
      if (value[0]?.steps) {
        this.selectedStructure = value[0]
        this.SET_STRUCTURE_SELECTED(value[0].id)
      }
    },
    reset() {
      this.selectedItem = null
    },
    selectStructure() {
      this.LOAD_STRUCTURE({ id: this.projectSelected })
    },
    clearStructure() {
      this.selectedItem = null
      this.REMOVE_AUTOCOMPLETE_STRUCTURE(null)
    },
    update() {
      this.UPDATE_STRUCTURE()
    },
    findIcon(componentType) {
      return Structure.find(item => item.componentType === componentType).icon
    },
  },
}
</script>

<style scoped>
.border-field {
  border: solid 1px #999;
  border-radius: 5px;
  padding: 10px 5px 5px 5px;
  margin-bottom: 5px;
}

.margin-option {
  margin-bottom: 5px;
}

.v-input--selection-controls {
  margin-top: 0px;
}

.custom-treeview {
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-treeview .v-treeview-node__root {
  padding: 10px 0;
  transition: background-color 0.3s ease;
}

.custom-treeview .v-treeview-node__content {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.custom-treeview .v-treeview-node:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.custom-treeview .v-treeview-node--active .v-treeview-node__content {
  background-color: #6200ea;
  color: white;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.custom-treeview .v-icon {
  color: #9f99a8;
  font-size: 18px;
}

.custom-treeview .v-treeview-node__toggle {
  color: #6200ea;
}

.custom-treeview .v-treeview-node__children {
  padding-left: 20px;
}

.custom-treeview .v-treeview-node__children {
  transition: max-height 0.3s ease-in-out;
}

.custom-treeview .v-treeview-node:hover .v-treeview-node__content {
  background-color: #e0e0e0;
  color: #6200ea;
  border-radius: 6px;
}

.custom-treeview .v-simple-checkbox {
  background-color: transparent;
}

.custom-treeview .v-simple-checkbox .v-icon {
  color: #6200ea;
}

.custom-treeview
  .v-treeview-node__root
  .v-treeview-node--active
  .primary--text {
  border-radius: 8px;
}
</style>
