<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog
        v-model="dialogCopy"
        transition="dialog-bottom-transition"
        max-width="600"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip v-if="!update" left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="blue darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Copiar relatório</span>
          </v-tooltip>
        </template>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Nova cópia</v-toolbar>
            <v-card-text>
              <v-form id="formCopy" ref="formCopy">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="formCopy.title"
                      label="Titulo"
                      outlined
                      dense
                      maxlength="100"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="green" text @click="createCopy"> Criar </v-btn>
              <v-btn color="red" text @click="closeCopy">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>

    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip v-if="update" left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="orange darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar step</span>
          </v-tooltip>

          <v-tooltip v-else left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="green darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nova step</span>
          </v-tooltip>
        </template>
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Insira as informações da nova step</v-toolbar
            >
            <v-card-text>
              <v-form id="form" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.title"
                      label="Titulo"
                      outlined
                      dense
                      maxlength="100"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="form.step"
                      :items="steps"
                      item-text="title"
                      item-value="id"
                      label="Posição"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="green" text @click="create">{{
                update ? 'Atualizar' : 'Criar'
              }}</v-btn>
              <v-btn color="red" text @click="close">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  CREATE_STEP,
  UPDATE_STEP,
  COPY_REPORT,
} from '../../../vuex/modules/structure'

export default {
  name: 'NewStructure',

  props: {
    report: {
      type: Object,
      required: true,
    },

    update: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialog: false,
    dialogCopy: false,

    form: {
      title: '',
      step: 1,
    },

    formCopy: {
      title: '',
    },

    rules: {
      required: value => !!value || 'Campo obrigatório.',
    },
  }),

  computed: {
    ...mapState('structure', ['data', 'loading', 'project']),

    steps() {
      const length = this.data.steps.length + 1

      const result = Array.from({ length }, (_, i) => ({
        id: i + 1,
        title: `Step ${i + 1}`,
      }))

      if (this.update) return result.slice(1, result.length - 1)

      return result.slice(1, result.length)
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.form = {
          title: '',
          step: 1,
        }
      }
    },
  },

  created() {
    if (this.update) {
      this.form = {
        title: this.report.title,
        step: Number(this.report.id),
      }
    }
  },

  methods: {
    ...mapActions('structure', [CREATE_STEP, UPDATE_STEP, COPY_REPORT]),

    create() {
      const valid = this.$refs.form.validate()

      if (!valid)
        return this.Toast().fire({
          icon: 'error',
          title: 'Por favor, preencha os campos obrigatórios.',
        })

      if (this.update) {
        this.UPDATE_STEP({
          ...this.form,
          id: this.report.id,
        })

        this.dialog = false
        return
      }

      this.CREATE_STEP({
        ...this.form,
      })

      this.dialog = false
    },

    createCopy() {
      const valid = this.$refs.formCopy.validate()

      if (!valid)
        return this.Toast().fire({
          icon: 'error',
          title: 'Por favor, preencha os campos obrigatórios.',
        })

      this.COPY_REPORT({
        ...this.formCopy,
        id: this.report.id,
      })

      this.dialogCopy = false
    },

    close() {
      this.dialog = false
      this.form = {
        title: '',
        step: 1,
      }
    },

    closeCopy() {
      this.dialogCopy = false
      this.formCopy = {
        title: '',
      }
    },
  },
}
</script>
